.box-video {
    margin: 60px 0 0;
    .video {
        @include flex(
            $justify: center
        );
        > a {
            display: block;
            max-width: 770px;
            position: relative;
            > figure {
                overflow: hidden;
                position: relative;
                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    @include transition;
                }
                &::before {
                    background-color: #000000;
                    left: 0;
                    opacity: 0.5;
                    top: 0;
                    @include size(100%);
                }
                &::after {
                    background-color: $brand-color-secondary;
                    border-radius: 50%;
                    @include iconeBg(
                        $content: "\e917",
                        $fontSize: 40px
                    );
                    color: #ffffff;
                    @include flex(
                        $align: center,
                        $justify: center
                    );
                    left: calc(50% - 50px);
                    top: calc(50% - 50px);
                    @include size(100px);
                }
            }
            >.caption {
                color: $text-color-light;
                font-size: 14px;
                line-height: em(22, 14);
                margin: 5px 0 0;
            }
            &:hover {
                > figure {
                    &::before {
                        opacity: 0.8;
                    }
                    &::after {
                        background-color: #ffffff;
                        color: $brand-color-secondary;
                    }
                }
            }
        }
    }
    @include breakpoint-max(large) {
        .video {
            > a {
                > figure {
                    &::after {
                        transform: scale(0.75);
                    }
                }
            }
        }
    }
}