.banner {
    @include flex(
        $justify: center
    );
    > a {
        box-shadow: 0 0 0 1px $border-color;
        display: block;
        position: relative;
        text-align: center;
        width: 100%;
        &::before {
            background-color: #000000;
            content: "";
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            @include size(100%);
            @include transition;
        }
        .banners-group & {
            padding: 14px;
        }
        &:hover {
            &::before {
                opacity: 0.2;
            }
        }
    }
}