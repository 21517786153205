.box-accordion {
    border: solid 2px $border-color;
    .accordion-item {
        & + .accordion-item {
            border-top: solid 2px $border-color;
        }
        .accordion-title {
            @include font-secondary(
                $weight: regular,
                $size: 24
            );
            cursor: pointer;
            padding: 10px 20px;
            position: relative;
            @include transition;
            &::before {
                @include iconeBg(
                    $content: "\e90b",
                    $fontSize: 10px
                );
                color: $text-color-light;
                position: absolute;
                right: 20px;
                top: calc(50% - 6px);
                transform: rotate(0deg);
                @include size(12px);
                @include transition;
                @include flex(
                    $align: center,
                    $justify: center
                );
            }
            &:hover {
                background-color: #f2f2f2;
            }
            &.active {
                background-color: $brand-color-secondary;
                color: #ffffff;
                &::before {
                    transform: rotate(90deg);
                    color: #ffffff;
                }
            }
        }
        .accordion-content {
            border-top: solid 2px $border-color;
            display: none;
            padding: 15px 20px;
            > ul {
                > li {
                    & + li {
                        margin: 10px 0 0;
                    }
                    > a {
                        color: $text-color-light;
                        display: block;
                        font-weight: weight(semibold);
                        @include transition;
                        &:hover {
                            color: $brand-color;
                        }
                    }
                }
            }
        }
        @include breakpoint-max(large) {
            .accordion-content {
                > ul {
                    > li {
                        & + li {
                            margin: 5px 0 0;
                        }
                        > a {
                            background-color: #e5e5e5;
                            border-radius: 3px;
                            padding: 15px;
                            text-align: center;
                            &:hover {
                                background-color: $brand-color-secondary;
                                color: #ffffff;
                            }
                        }
                    }
                }
            }
        }
    }
}