.card-news {
    > a {
        box-shadow: 0 0 20px rgba(#000000, 0.1);
        display: block;
        .card-image {
            .image-inner {
                background-color: #f1f1f1;
            }
        }
        .card-content {
            @include transition(
                $duration: 0.5s
            );
        }
        .card-date {
            @include transition;
        }
        .card-title {
            @include transition;
        }
        .box-text {
            > p {
                @include transition;
            }
        }
        .card-link {
            @include transition(
                $duration: 0.5s
            );
            &::after {
                @include iconeBg(
                    $content: "\e907",
                    $fontSize: 10px
                );
                @include flex(
                    $justify: flex-end
                );
                color: $brand-color-secondary;
                position: absolute;
                right: 0;
                top: calc(50% - 5px);
                width: 100%;
                overflow: hidden;
                transform: translateX(0);
                z-index: 1;
                @include transition;
            }
        }
        .-img-column & {
            @include flex();
            .card-image {
                width: percent(240, 570);
            }
            .card-content {
                padding: 45px 15px 25px;
                width: percent(330, 570);
            }
            .card-date {
                left: 15px;
            }
        }
        .-img-row & {
            @include flex(
                $direction: column
            );
            max-width: 370px;
            .card-content {
                padding: 20px;
                border-width: 0;
            }
            .card-date {
                bottom: 100%;
                left: 20px;
                text-align: center;
                top: auto;
                width: calc(100% - 40px);
            }
        }

        &:hover {
            .card-content {
                background-color: $brand-color;
                border-color: $brand-color-secondary;
            }
            .card-title {
                color: #ffffff;
            }
            .box-text {
                > p {
                    color: #ffffff;
                }
            }
            .card-date {
                background-color: $brand-color-secondary;
                color: $brand-color;
            }
            .card-link {
                color: $brand-color-secondary;
                &::after {
                    right: 0;
                    transform: translateX(24px);
                    width: 15px;
                }
            }
        }
    }

    @include breakpoint-max(small) {
        .-img-column & {
            > a {
                .card-image {
                    width: 100%;
                    .image-inner {
                        border-width: 2px 2px 0;
                        border-style: solid;
                        border-color: $border-color;
                        @include flex(
                            $justify: center
                        );
                        padding: 10px;
                    }
                }
                .card-content {
                    width: 100%;
                }
            }
        }
    }
}