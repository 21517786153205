.page-links-uteis {
    .category {
        & + .category {
            margin: 40px 0 0;
        }
        .box-text {
            > ul {
                > li {
                    &::before {
                        top: 4px;
                    }
                    > a {
                        color: $text-color-light;
                        display: table;
                        &:hover {
                            color: $brand-color-secondary;
                        }
                    }
                }
            }
        }
        .page-subtitle {
            font-size: 32px;
            font-weight: weight(regular, secondary);
            line-height: em(32, 32);
            margin: 0 0 20px;
        }

        @include breakpoint-min(large) {
            &.column {
                .box-text {
                    > ul {
                        @include flex(
                            $align: flex-start
                        );
                        > li {
                            margin: 0 0 10px;
                            padding: 0 15px;
                            width: 50%;
                        }
                    }
                }
            }
        }

        @include breakpoint-max(large) {
            .page-subtitle {
                text-align: center;
            }
            .box-text {
                > ul {
                    @include flex(
                        $align: stretch,
                        $justify: center
                    );
                    margin: 0 -2.5px -5px;
                    > li {
                        padding: 0 2.5px;
                        margin: 0 0 5px;
                        width: 33.33%;
                        &::before {
                            display: none;
                        }
                        > a {
                            background-color: #e5e5e5;
                            @include flex(
                                $align: center,
                                $justify: center
                            );
                            padding: 15px 20px;
                            text-align: center;
                            height: 100%;
                            &:hover {
                                background-color: $brand-color-secondary;
                                color: #ffffff;
                            }
                        }
                    }
                }
            }
        }

        @include breakpoint-max(medium) {
            .box-text {
                > ul {
                    > li {
                        width: 50%;
                    }
                }
            }
        }

        @include breakpoint-max(small) {
            .box-text {
                > ul {
                    > li {
                        width: 100%;
                    }
                }
            }
        }
    }
}