.box-gallery {
    .item {
        > a {
            display: block;
            position: relative;
            > figure {
                overflow: hidden;
                position: relative;
                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    @include transition;
                    opacity: 0;
                }
                &::before {
                    background-color: #000000;
                    left: 0;
                    opacity: 0.5;
                    top: 0;
                    @include size(100%);
                    z-index: 1;
                }
                &::after {
                    background-color: $brand-color-secondary;
                    border-radius: 50%;
                    @include iconeBg(
                        $content: "\e91a",
                        $fontSize: 30px
                    );
                    color: #ffffff;
                    @include flex(
                        $align: center,
                        $justify: center
                    );
                    left: calc(50% - 32px);
                    top: 100%;
                    @include size(64px);
                    z-index: 2;
                }
            }
            >.caption {
                color: $text-color-light;
                font-size: 14px;
                line-height: em(22, 14);
                margin: 5px 0 0;
            }
            &:hover {
                > figure {
                    &::before,
                    &::after {
                        opacity: 1;
                    }
                    &::before {
                        opacity: 0.8;
                    }
                    &::after {
                        top: calc(50% - 32px);
                    }
                }
            }
        }
    }
    @include breakpoint-max(large) {
        .item {
            > a {
                > figure {
                    &::after {
                        transform: scale(0.8);
                    }
                }
            }
        }
    }
}