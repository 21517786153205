.page-about {
    .box-mission {
        background-color: $brand-color;
        margin: 40px 0 0;
        padding: 50px;
        .title {
            color: $brand-color-secondary;
            font-size: 42px;
            line-height: em(42, 42);
            margin: 0 0 10px;
            text-transform: uppercase;
        }
        .banner {
            justify-content: flex-start;
            > a {
                width: auto;
            }
        }
        .box-text {
            color: #ffffff;
        }
    }

    @include breakpoint-min(large) {
        .box-mission {
            padding: 65px 55px;
            position: relative;
            z-index: 1;
            &::before {
                @include imageBg(
                    $image: "bg-mission.jpg",
                    $size: cover
                );
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                @include size(1245px, 100%);
                z-index: -1;
            }
        }
    }

    @include breakpoint-max(medium) {
        .box-mission {
            padding: 20px;
            .row {
                align-items: center;
                flex-direction: column;
            }
            div[class*="grid-"] {
                text-align: center;
                width: auto;
                & + div {
                    margin: 20px 0 0;
                }
            }
        }
    }
}