.box-downloads {
    margin: 30px 0 0;
    > ul {
        > li {
            & + li {
                margin: 10px 0 0;
            }
            .btn-link {
                > a {
                    color: $brand-color-secondary;
                    @include flex(
                        $align: center,
                        $justify: space-between
                    );
                    font-weight: weight(semibold);
                    text-align: left;
                    text-transform: none;
                    white-space: normal;
                    width: 100%;
                    >.title {
                        width: calc(100% - 90px);
                    }
                    >.btn {
                        @include flex(
                            $align: center,
                            $justify: center
                        );
                        height: 100%;
                        text-align: center;
                        text-transform: uppercase;
                        width: 80px;
                    }
                    &:hover {
                        color: #ffffff;
                    }
                }
            }
        }
    }
}