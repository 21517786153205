.page-covenants {
    .list-items {
        >.row {
            justify-content: center;
            margin-bottom: -30px;
        }
    }
    .item {
        margin: 0 0 30px;
        padding: 0 15px;
        width: 20%;
        > a {
            @include flex(
                $direction: column,
                $align: center
            );
            text-align: center;
            @include transition;
            > img {
                margin: 0 0 20px;
            }
            >.title {
                color: $text-color-light;
                font-size: 28px;
                font-weight: weight(regular, secondary);
                @include transition;
            }
        }
    }
    .banner-covenants {
        background-color: #486dad;
        margin: 120px 0 0;
        padding: 50px 35px;
        @include flex(
            $align: center,
            $justify: center
        );
        >.title {
            color: $brand-color-secondary;
            font-size: 40px;
            font-weight: weight(regular, secondary);
            padding: 0 15px;
            width: 50%;
        }
        >.content {
            color: #ffffff;
            padding: 0 15px;
            width: 50%;
            >.phone {
                font-size: 20px;
                font-weight: weight(bold);
                line-height: 1.2em;
                text-transform: uppercase;
                > strong {
                    font-size: 30px;
                    line-height: 1.2em;
                }
            }
            >.text {
                font-size: 17px;
                line-height: 1.2em;
                margin: 5px 0 0;
            }
        }
    }

    @include breakpoint-min(large) {
        .item {
            > a {
                transform: translateY(0);
                &:hover {
                    transform: translateY(-15px);
                    >.title {
                        color: $brand-color;
                    }
                }
            }
        }
        .banner-covenants {
            @include imageBg(
                $image: "bg-indica-convenio.jpg",
                $size: cover
            );
        }
    }
    @include breakpoint-max(large) {
        .item {
            width: 100%;
            > a {
                box-shadow: 0 0 20px rgba(#000000, 0.1);
                padding: 20px;
                &:hover {
                    background-color: #fafafa;
                }
            }
        }
        .banner-covenants {
            align-items: center;
            flex-direction: column;
            padding: 20px;
            .title,
            .content {
                text-align: center;
                width: auto;
            }
            .title {
                margin: 0 0 20px;
            }
            .content {
                >.phone {
                    > strong {
                        display: block;
                    }
                }
            }
        }
    }
}