.page-services {
    .list-items {
        >.row {
            justify-content: center;
            margin-bottom: -30px;
        }
    }
    .item {
        margin: 0 0 30px;
        > a {
            @include flex(
                $direction: column,
                $align: center
            );
            text-align: center;
            @include transition;
            > img {
                margin: 0 0 20px;
            }
            >.title {
                color: $text-color-light;
                font-size: 28px;
                font-weight: weight(regular, secondary);
                @include transition;
            }
        }
    }

    @include breakpoint-min(large) {
        .item {
            > a {
                transform: translateY(0);
                &:hover {
                    transform: translateY(-15px);
                    >.title {
                        color: $brand-color;
                    }
                }
            }
        }
    }
    @include breakpoint-max(large) {
        .item {
            width: 100%;
            > a {
                box-shadow: 0 0 20px rgba(#000000, 0.1);
                padding: 20px;
                &:hover {
                    background-color: #fafafa;
                }
            }
        }
    }
}