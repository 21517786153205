@charset "UTF-8";
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?grrme1");
  src: url("../fonts/icomoon.eot?grrme1#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?grrme1") format("truetype"), url("../fonts/icomoon.woff?grrme1") format("woff"), url("../fonts/icomoon.svg?grrme1#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-arrow-up:before {
  content: "\e900"; }

.icon-arrow-down:before {
  content: "\e901"; }

.icon-arrow-left:before {
  content: "\e902"; }

.icon-arrow-right:before {
  content: "\e903"; }

.icon-long-arrow-up:before {
  content: "\e904"; }

.icon-long-arrow-down:before {
  content: "\e905"; }

.icon-long-arrow-left:before {
  content: "\e906"; }

.icon-long-arrow-right:before {
  content: "\e907"; }

.icon-caret-top:before {
  content: "\e908"; }

.icon-caret-down:before {
  content: "\e909"; }

.icon-caret-left:before {
  content: "\e90a"; }

.icon-caret-right:before {
  content: "\e90b"; }

.icon-chevron-line-up:before {
  content: "\e90c"; }

.icon-chevron-line-down:before {
  content: "\e90d"; }

.icon-chevron-line-left:before {
  content: "\e90e"; }

.icon-chevron-line-right:before {
  content: "\e90f"; }

.icon-chevron-up:before {
  content: "\e910"; }

.icon-chevron-down:before {
  content: "\e911"; }

.icon-chevron-left:before {
  content: "\e912"; }

.icon-chevron-right:before {
  content: "\e913"; }

.icon-add:before {
  content: "\e914"; }

.icon-close:before {
  content: "\e915"; }

.icon-check:before {
  content: "\e916"; }

.icon-play-button-sing:before {
  content: "\e917"; }

.icon-shield:before {
  content: "\e918"; }

.icon-road:before {
  content: "\e919"; }

.icon-zoom:before {
  content: "\e91a"; }

.icon-download:before {
  content: "\e91b"; }

.icon-hours:before {
  content: "\e91c"; }

.icon-calendar:before {
  content: "\e91d"; }

.icon-check-in:before {
  content: "\e91e"; }

.icon-contact-book:before {
  content: "\e91f"; }

.icon-gdpr:before {
  content: "\e920"; }

.icon-id-card:before {
  content: "\e921"; }

.icon-law-book:before {
  content: "\e922"; }

.icon-skyline:before {
  content: "\e923"; }

.icon-smartphone:before {
  content: "\e924"; }

.icon-support:before {
  content: "\e925"; }

.icon-tag:before {
  content: "\e926"; }

.icon-team:before {
  content: "\e927"; }

.icon-clock:before {
  content: "\e928"; }

.icon-envelope:before {
  content: "\e929"; }

.icon-paper-plane:before {
  content: "\e92a"; }

.icon-placeholder:before {
  content: "\e92b"; }

.icon-telephone:before {
  content: "\e92c"; }

.icon-whatsapp:before {
  content: "\e92d"; }

.icon-facebook:before {
  content: "\e92e"; }

.icon-instagram:before {
  content: "\e92f"; }

.icon-twitter:before {
  content: "\e930"; }

.icon-youtube-play:before {
  content: "\e931"; }

.icon-flickr:before {
  content: "\e932"; }

.icon-linkedin:before {
  content: "\e933"; }

.icon-tdh-logo:before {
  content: "\e934"; }

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */ }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px); }

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%; }

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none; }

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit; }

.owl-carousel.owl-loaded {
  display: block; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */ }

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease; }

.owl-carousel .owl-item .owl-lazy[src^=""],
.owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

body.compensate-for-scrollbar {
  overflow: hidden; }

.fancybox-active {
  height: auto; }

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden; }

.fancybox-container {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transform: translateZ(0);
  width: 100%;
  z-index: 99992; }

.fancybox-container * {
  box-sizing: border-box; }

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto; }

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }

.fancybox-is-open .fancybox-bg {
  opacity: .9;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity .25s ease, visibility 0s ease .25s;
  visibility: hidden;
  z-index: 99997; }

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
  opacity: 1;
  transition: opacity .25s ease 0s, visibility 0s ease 0s;
  visibility: visible; }

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.fancybox-toolbar {
  right: 0;
  top: 0; }

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  transform: translateZ(0);
  z-index: 99994; }

.fancybox-is-open .fancybox-stage {
  overflow: hidden; }

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  /* Using without prefix would break IE11 */
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: transform, opacity;
  white-space: normal;
  width: 100%;
  z-index: 99994; }

.fancybox-slide::before {
  content: '';
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0; }

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block; }

.fancybox-slide--image {
  overflow: hidden;
  padding: 44px 0; }

.fancybox-slide--image::before {
  display: none; }

.fancybox-slide--html {
  padding: 6px; }

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: relative;
  text-align: left;
  vertical-align: middle; }

.fancybox-slide--image .fancybox-content {
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -ms-transform-origin: top left;
  transform-origin: top left;
  transition-property: transform, opacity;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995; }

.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out; }

.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in; }

.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab; }

.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.fancybox-container [data-selectable='true'] {
  cursor: text; }

.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.fancybox-spaceball {
  z-index: 1; }

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: 100%;
  overflow: visible;
  padding: 0;
  width: 100%; }

.fancybox-slide--video .fancybox-content {
  background: #000; }

.fancybox-slide--map .fancybox-content {
  background: #e5e3df; }

.fancybox-slide--iframe .fancybox-content {
  background: #fff; }

.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%; }

/* Fix iOS */
.fancybox-iframe {
  left: 0;
  position: absolute;
  top: 0; }

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%; }

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0; }

/* Buttons */
.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: relative;
  transition: color .2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px; }

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc; }

.fancybox-button:hover {
  color: #fff; }

.fancybox-button:focus {
  outline: none; }

.fancybox-button.fancybox-focus {
  outline: 1px dotted; }

.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: none; }

/* Fix IE11 */
.fancybox-button div {
  height: 100%; }

.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%; }

.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0; }

.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
  display: none; }

.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
  display: none; }

.fancybox-progress {
  background: #ff5268;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -ms-transform-origin: 0;
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 99998; }

/* Close button on the top right corner of html content */
.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: .8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401; }

.fancybox-close-small:hover {
  color: #fff;
  opacity: 1; }

.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0; }

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden; }

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none; }

/* Navigation arrows */
.fancybox-navigation .fancybox-button {
  background-clip: content-box;
  height: 100px;
  opacity: 0;
  position: absolute;
  top: calc(50% - 50px);
  width: 70px; }

.fancybox-navigation .fancybox-button div {
  padding: 7px; }

.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  left: env(safe-area-inset-left);
  padding: 31px 26px 31px 6px; }

.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0;
  right: env(safe-area-inset-right); }

/* Caption */
.fancybox-caption {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.15) 65%, rgba(0, 0, 0, 0.075) 75.5%, rgba(0, 0, 0, 0.037) 82.85%, rgba(0, 0, 0, 0.019) 88%, rgba(0, 0, 0, 0) 100%);
  bottom: 0;
  color: #eee;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 75px 44px 25px 44px;
  pointer-events: none;
  right: 0;
  text-align: center;
  z-index: 99996; }

.fancybox-caption--separate {
  margin-top: -50px; }

.fancybox-caption__body {
  max-height: 50vh;
  overflow: auto;
  pointer-events: all; }

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none; }

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline; }

/* Loading indicator */
.fancybox-loading {
  animation: fancybox-rotate 1s linear infinite;
  background: transparent;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: .7;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 99999; }

@keyframes fancybox-rotate {
  100% {
    transform: rotate(360deg); } }

/* Transition effects */
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  transform: translate3d(-100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  transform: translate3d(100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1; }

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(1.5, 1.5, 1.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1); }

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg); }

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -ms-transform: rotate(360deg);
  transform: rotate(360deg); }

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -ms-transform: rotate(0deg);
  transform: rotate(0deg); }

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0); }

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }

.fancybox-fx-tube.fancybox-slide--next {
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }

.fancybox-fx-tube.fancybox-slide--current {
  transform: translate3d(0, 0, 0) scale(1); }

/* Styling for Small-Screen Devices */
@media all and (max-height: 576px) {
  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px; }
  .fancybox-slide--image {
    padding: 6px 0; }
  .fancybox-close-small {
    right: -6px; }
  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px; }
  .fancybox-caption {
    padding-left: 12px;
    padding-right: 12px; } }

/* Share */
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center; }

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px 0; }

.fancybox-share p {
  margin: 0;
  padding: 0; }

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px 5px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap; }

.fancybox-share__button:visited,
.fancybox-share__button:link {
  color: #fff; }

.fancybox-share__button:hover {
  text-decoration: none; }

.fancybox-share__button--fb {
  background: #3b5998; }

.fancybox-share__button--fb:hover {
  background: #344e86; }

.fancybox-share__button--pt {
  background: #bd081d; }

.fancybox-share__button--pt:hover {
  background: #aa0719; }

.fancybox-share__button--tw {
  background: #1da1f2; }

.fancybox-share__button--tw:hover {
  background: #0d95e8; }

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px; }

.fancybox-share__button svg path {
  fill: #fff; }

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%; }

/* Thumbs */
.fancybox-thumbs {
  background: #ddd;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px 2px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  top: 0;
  width: 212px;
  z-index: 99995; }

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden; }

.fancybox-show-thumbs .fancybox-thumbs {
  display: block; }

.fancybox-show-thumbs .fancybox-inner {
  right: 212px; }

.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%; }

.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px; }

.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px; }

.fancybox-thumbs__list a::before {
  border: 6px solid #ff5268;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991; }

.fancybox-thumbs__list a:focus::before {
  opacity: .5; }

.fancybox-thumbs__list a.fancybox-thumbs-active::before {
  opacity: 1; }

/* Styling for Small-Screen Devices */
@media all and (max-width: 576px) {
  .fancybox-thumbs {
    width: 110px; }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px; }
  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px); } }

@media (min-width: 992px) {
  .site-header .site-name {
    letter-spacing: 2px; }
    .site-header .site-name > .fundation {
      padding: 0 0 0 15px;
      position: relative; }
      .site-header .site-name > .fundation::before {
        background-color: #777777;
        content: "";
        left: 0;
        position: absolute;
        top: calc(50% - 10px);
        height: 16px;
        width: 2px; } }

.site-nav {
  position: relative; }
  @media (min-width: 992px) {
    .site-nav::after {
      background-color: #e5e5e5;
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      height: 1px;
      width: 1500%; }
    .site-nav ul > li > a {
      transition: color 0.3s ease; }
      .site-nav ul > li > a:hover, .site-nav ul > li > a.active {
        color: #ffba00; } }
  @media (max-width: 991px) {
    .site-nav > .btn-toggle-menu {
      position: relative; }
      .site-nav > .btn-toggle-menu::before {
        content: "";
        background-image: url(../images/backgrounds/menu.svg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 35px;
        position: absolute;
        right: 15px;
        top: calc(50% - 17.5px);
        height: 35px;
        width: 35px; }
    .site-nav ul > li > a {
      background-color: rgba(0, 0, 0, 0.15);
      color: #ffffff;
      transition: all 0.3s ease; }
      .site-nav ul > li > a:hover, .site-nav ul > li > a.active {
        background-color: #ffba00;
        columns: #ffffff; } }

.breadcrumbs ol::before, .breadcrumbs ol::after {
  content: "";
  position: absolute; }

.breadcrumbs ol::before {
  background-color: #22509d;
  height: 100%;
  width: 1000%;
  right: 100%;
  top: 0;
  z-index: -1; }

.breadcrumbs ol::after {
  background-color: #ffffff;
  height: 2px;
  width: 1000%;
  right: 0;
  top: calc(50% - 1px);
  z-index: 1; }

.breadcrumbs ol > li + li::before {
  content: ""; }

.breadcrumbs ol > li > a {
  transition: all 0.3s ease; }
  .breadcrumbs ol > li > a:hover {
    color: #ffba00; }

.site-footer .links-footer > .webmail {
  transition: all 0.3s ease; }
  .site-footer .links-footer > .webmail:hover {
    background-color: #ffba00; }

.site-footer .links-footer > .social-media > li > a {
  transition: all 0.3s ease; }
  .site-footer .links-footer > .social-media > li > a > i {
    transition: all 0.6s ease; }
  .site-footer .links-footer > .social-media > li > a:hover {
    color: #ffba00; }
    .site-footer .links-footer > .social-media > li > a:hover > i {
      background-color: #ffba00; }

.site-footer .utils-links > ul > li > a {
  transition: all 0.3s ease;
  transform: translateX(0); }
  .site-footer .utils-links > ul > li > a:hover {
    color: #ffba00;
    transform: translateX(5px); }

.site-footer .contacts .back-top > a {
  transition: all 0.3s ease; }
  .site-footer .contacts .back-top > a::after {
    content: "";
    font-family: 'icomoon' !important;
    font-size: 8px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color: #ffba00;
    display: block;
    margin: 3px 0 0 12px;
    transition: all 0.3s ease;
    transform: translateY(0); }
  .site-footer .contacts .back-top > a:hover {
    color: #ffba00; }
    .site-footer .contacts .back-top > a:hover::after {
      transform: translateY(-5px); }

.site-footer .bottom > .tdh {
  transition: all 0.3s ease; }
  .site-footer .bottom > .tdh:hover {
    color: #22509d; }

@media (max-width: 991px) {
  .site-footer .utils-links > ul > li > a {
    transform: translate(0) !important; }
    .site-footer .utils-links > ul > li > a:hover {
      background-color: #ffba00;
      color: #ffffff; } }

.btn-link > a {
  transition: all 0.5s ease; }
  .btn-link > a.active, .btn-link > a:hover {
    background-color: #ffba00;
    color: #ffffff; }

.btn-link.-blue > a::before, .btn-link.-blue > a::after {
  background-color: #22509d; }

.btn-link.-blue > a.active, .btn-link.-blue > a:hover {
  background-color: #22509d; }

@media (min-width: 992px) {
  .btn-link > a::before, .btn-link > a::after {
    background-color: #ffba00;
    content: "";
    pointer-events: none;
    position: absolute;
    height: 100%;
    width: 100%;
    transform: rotate(-25deg);
    transition: all 0.5s ease; }
  .btn-link > a::before {
    right: 50%;
    bottom: 50%;
    transform-origin: 50% 50%; }
  .btn-link > a::after {
    left: 50%;
    top: 50%;
    transform-origin: 50% 50%; }
  .btn-link > a.active::before, .btn-link > a:hover::before {
    right: 100%;
    bottom: 100%; }
  .btn-link > a.active::after, .btn-link > a:hover::after {
    left: 100%;
    top: 100%; } }

.box-text a {
  transition: all 0.3s ease; }

.box-text > ul > li {
  padding: 0 0 0 15px;
  position: relative; }
  .box-text > ul > li::before {
    content: "";
    font-family: 'icomoon' !important;
    font-size: 8px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color: #ffba00;
    position: absolute;
    position: absolute;
    left: 0;
    top: 7px; }

.box-search .form-search .form-field {
  transition: all 0.3s ease; }
  .box-search .form-search .form-field::-webkit-input-placeholder {
    color: #777777;
    opacity: 1; }
  .box-search .form-search .form-field:-moz-placeholder {
    color: #777777;
    opacity: 1; }
  .box-search .form-search .form-field::-moz-placeholder {
    color: #777777;
    opacity: 1; }
  .box-search .form-search .form-field:-ms-input-placeholder {
    color: #777777;
    opacity: 1; }
  .box-search .form-search .form-field:focus {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15); }

.box-search .form-search .form-button {
  cursor: pointer;
  text-align: left;
  text-indent: -9999px;
  transition: all 0.3s ease; }
  .box-search .form-search .form-button::before {
    content: "";
    font-family: 'icomoon' !important;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color: #ffffff;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    left: 0;
    top: 0;
    position: absolute;
    text-indent: 0;
    height: 100%;
    width: 100%; }
  .box-search .form-search .form-button:hover {
    background-color: #ffba00; }

.box-title {
  position: relative;
  z-index: 1; }
  .box-title > .title {
    position: relative; }
    .box-title > .title::before {
      content: "";
      font-family: 'icomoon' !important;
      font-size: 85px;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      color: #ececec;
      display: block;
      margin: 0 -105px 0 0;
      height: 90px;
      width: 170px;
      position: relative;
      transform: translateY(-8px);
      z-index: -1; }
  .box-title.-secondary > .title::before {
    color: #000000;
    opacity: 0.15; }
  .box-title.-subtitle > .title::before {
    height: 60px;
    width: 120px;
    font-size: 60px;
    margin: 0 -70px 0 0; }
  @media (max-width: 991px) {
    .box-title > .title::before {
      font-size: 50px;
      height: 50px;
      width: 100px;
      margin: 0 -50px 0 0; } }
  @media (max-width: 575px) {
    .box-title > .title {
      padding: 15px 0 0; }
      .box-title > .title::before {
        position: absolute;
        top: 0;
        left: calc(50% - 50px);
        transform: translateY(0); } }

.box-gallery-photos {
  background-color: #dad9d8;
  padding: 40px 0 60px;
  width: 100%; }
  .box-gallery-photos .box-title > .title {
    color: #22509d; }
    .box-gallery-photos .box-title > .title::before {
      color: #ced2d5; }
  .box-gallery-photos .list-items > .row {
    margin-bottom: -30px;
    justify-content: center; }
  .box-gallery-photos .btn-link {
    margin: 50px 0 0; }
  @media (min-width: 992px) {
    .box-gallery-photos {
      background-image: url(../images/backgrounds/bg-image.jpg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover; } }
  @media (max-width: 991px) {
    .box-gallery-photos {
      background-image: linear-gradient(45deg, #dad9d8, #f8f8f7); } }

.box-gallery-video {
  background-color: #193b73;
  margin: 0 auto;
  max-width: 1920px;
  padding: 40px 0 60px;
  width: 100%; }
  .box-gallery-video.-home {
    margin-top: 60px; }
  .box-gallery-video .list-items > .row {
    margin-bottom: -30px;
    justify-content: center; }
  .box-gallery-video .btn-link {
    margin: 50px 0 0; }
  @media (min-width: 992px) {
    .box-gallery-video {
      background-image: url(../images/backgrounds/bg-video.jpg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover; } }
  @media (max-width: 991px) {
    .box-gallery-video {
      background-image: linear-gradient(45deg, #193b73, #22509d); } }

.card-photo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 0 30px; }
  .card-photo > a {
    background-color: #22509d;
    display: block;
    height: 100%;
    position: relative;
    transition: all 0.3s ease; }
    .-light .card-photo > a {
      background-color: #ffffff; }
    .card-photo > a > figure {
      overflow: hidden;
      position: relative; }
      .card-photo > a > figure::before, .card-photo > a > figure::after {
        content: "";
        position: absolute;
        transition: all 0.3s ease;
        opacity: 0; }
      .card-photo > a > figure::before {
        background-color: #000000;
        left: 0;
        opacity: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 1; }
      .card-photo > a > figure::after {
        background-color: #ffba00;
        border-radius: 50%;
        content: "";
        font-family: 'icomoon' !important;
        font-size: 30px;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        color: #ffffff;
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        left: calc(50% - 32px);
        top: 100%;
        height: 64px;
        width: 64px;
        z-index: 2; }
    .card-photo > a > .title {
      color: #ffba00;
      font-size: 26px;
      font-weight: 400;
      line-height: 1em;
      padding: 15px 15px 12px;
      text-align: center;
      text-transform: uppercase;
      transition: all 0.3s ease; }
    .card-photo > a:hover {
      background-color: #ffba00; }
      .card-photo > a:hover > figure::before, .card-photo > a:hover > figure::after {
        opacity: 1; }
      .card-photo > a:hover > figure::before {
        opacity: 0.8; }
      .card-photo > a:hover > figure::after {
        top: calc(50% - 32px); }
      .card-photo > a:hover > .title {
        color: #ffffff; }
  .page-physical-structure .card-photo > a {
    max-width: 370px;
    margin: 0 auto; }
  @media (max-width: 1199px) {
    .card-photo {
      width: 33.33%; }
      .page-physical-structure .card-photo {
        width: 50%; } }
  @media (max-width: 991px) {
    .card-photo {
      width: 100%; }
      .card-photo > a > .title {
        font-size: 20px; } }

.card-video {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 0 30px; }
  .card-video > a {
    background-color: #22509d;
    display: block;
    height: 100%;
    position: relative;
    transition: all 0.3s ease; }
    .-light .card-video > a {
      background-color: #ffffff; }
    .card-video > a > .image {
      background-color: #000000;
      position: relative; }
      .card-video > a > .image::before, .card-video > a > .image::after {
        content: "";
        left: 0;
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 1; }
      .card-video > a > .image::before {
        background-image: url(../images/backgrounds/icon-play.png);
        background-position: center center;
        background-repeat: no-repeat; }
      .card-video > a > .image::after {
        background-image: url("../images/backgrounds/card-video-line-left.png"), url("../images/backgrounds/card-video-line-right.png");
        background-position: 0 center, right center;
        background-repeat: no-repeat; }
      .card-video > a > .image > img {
        opacity: 0.5;
        transition: all 0.3s ease; }
    .card-video > a > .title {
      color: #ffba00;
      font-size: 26px;
      font-weight: 400;
      line-height: 1em;
      padding: 15px 15px 12px;
      text-align: center;
      text-transform: uppercase;
      transition: all 0.3s ease; }
    .card-video > a:hover {
      background-color: #ffba00; }
      .card-video > a:hover > .image > img {
        opacity: 0.35; }
      .card-video > a:hover > .title {
        color: #ffffff; }
  @media (max-width: 1199px) {
    .card-video {
      width: 33.33%; } }
  @media (max-width: 991px) {
    .card-video {
      width: 50%; }
      .card-video > a > .title {
        font-size: 20px; } }
  @media (max-width: 767px) {
    .card-video {
      width: 100%; } }

.box-news .list-items > .row {
  margin-bottom: -30px; }

.card-news > a {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: block; }
  .card-news > a .card-image .image-inner {
    background-color: #f1f1f1; }
  .card-news > a .card-content {
    transition: all 0.5s ease; }
  .card-news > a .card-date {
    transition: all 0.3s ease; }
  .card-news > a .card-title {
    transition: all 0.3s ease; }
  .card-news > a .box-text > p {
    transition: all 0.3s ease; }
  .card-news > a .card-link {
    transition: all 0.5s ease; }
    .card-news > a .card-link::after {
      content: "";
      font-family: 'icomoon' !important;
      font-size: 10px;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
      color: #ffba00;
      position: absolute;
      right: 0;
      top: calc(50% - 5px);
      width: 100%;
      overflow: hidden;
      transform: translateX(0);
      z-index: 1;
      transition: all 0.3s ease; }
  .-img-column .card-news > a {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .-img-column .card-news > a .card-image {
      width: 42.10526%; }
    .-img-column .card-news > a .card-content {
      padding: 45px 15px 25px;
      width: 57.89474%; }
    .-img-column .card-news > a .card-date {
      left: 15px; }
  .-img-row .card-news > a {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 370px; }
    .-img-row .card-news > a .card-content {
      padding: 20px;
      border-width: 0; }
    .-img-row .card-news > a .card-date {
      bottom: 100%;
      left: 20px;
      text-align: center;
      top: auto;
      width: calc(100% - 40px); }
  .card-news > a:hover .card-content {
    background-color: #22509d;
    border-color: #ffba00; }
  .card-news > a:hover .card-title {
    color: #ffffff; }
  .card-news > a:hover .box-text > p {
    color: #ffffff; }
  .card-news > a:hover .card-date {
    background-color: #ffba00;
    color: #22509d; }
  .card-news > a:hover .card-link {
    color: #ffba00; }
    .card-news > a:hover .card-link::after {
      right: 0;
      transform: translateX(24px);
      width: 15px; }

@media (max-width: 575px) {
  .-img-column .card-news > a .card-image {
    width: 100%; }
    .-img-column .card-news > a .card-image .image-inner {
      border-width: 2px 2px 0;
      border-style: solid;
      border-color: #e5e5e5;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      padding: 10px; }
  .-img-column .card-news > a .card-content {
    width: 100%; } }

.box-filters-news .btn-toggle-filter {
  position: relative; }
  .box-filters-news .btn-toggle-filter::before {
    background-image: url(../images/backgrounds/filter-results-button.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 20px;
    content: "";
    right: 15px;
    top: calc(50% - 10px);
    position: absolute;
    height: 20px;
    width: 20px; }

.load-more {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 40px 0 0; }
  .load-more > button {
    background-color: #ffffff;
    border: solid 2px #ffba00;
    color: #22509d;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: 700;
    line-height: 1em;
    overflow: hidden;
    padding: 15px 35px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.5s ease;
    white-space: nowrap;
    z-index: 1; }
    .load-more > button::before, .load-more > button::after {
      background-color: #ffba00;
      content: "";
      pointer-events: none;
      position: absolute;
      height: 100%;
      width: 100%;
      transform: rotate(-25deg);
      transition: all 0.5s ease; }
    .load-more > button::before {
      right: 50%;
      bottom: 50%;
      transform-origin: 50% 50%; }
    .load-more > button::after {
      left: 50%;
      top: 50%;
      transform-origin: 50% 50%; }
    .load-more > button:hover {
      background-color: #ffba00;
      color: #ffffff; }
      .load-more > button:hover::before {
        right: 100%;
        bottom: 100%; }
      .load-more > button:hover::after {
        left: 100%;
        top: 100%; }

.box-gallery .item > a {
  display: block;
  position: relative; }
  .box-gallery .item > a > figure {
    overflow: hidden;
    position: relative; }
    .box-gallery .item > a > figure::before, .box-gallery .item > a > figure::after {
      content: "";
      position: absolute;
      transition: all 0.3s ease;
      opacity: 0; }
    .box-gallery .item > a > figure::before {
      background-color: #000000;
      left: 0;
      opacity: 0.5;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 1; }
    .box-gallery .item > a > figure::after {
      background-color: #ffba00;
      border-radius: 50%;
      content: "";
      font-family: 'icomoon' !important;
      font-size: 30px;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      color: #ffffff;
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      left: calc(50% - 32px);
      top: 100%;
      height: 64px;
      width: 64px;
      z-index: 2; }
  .box-gallery .item > a > .caption {
    color: #777777;
    font-size: 14px;
    line-height: 1.57143em;
    margin: 5px 0 0; }
  .box-gallery .item > a:hover > figure::before, .box-gallery .item > a:hover > figure::after {
    opacity: 1; }
  .box-gallery .item > a:hover > figure::before {
    opacity: 0.8; }
  .box-gallery .item > a:hover > figure::after {
    top: calc(50% - 32px); }

@media (max-width: 991px) {
  .box-gallery .item > a > figure::after {
    transform: scale(0.8); } }

.box-video {
  margin: 60px 0 0; }
  .box-video .video {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center; }
    .box-video .video > a {
      display: block;
      max-width: 770px;
      position: relative; }
      .box-video .video > a > figure {
        overflow: hidden;
        position: relative; }
        .box-video .video > a > figure::before, .box-video .video > a > figure::after {
          content: "";
          position: absolute;
          transition: all 0.3s ease; }
        .box-video .video > a > figure::before {
          background-color: #000000;
          left: 0;
          opacity: 0.5;
          top: 0;
          height: 100%;
          width: 100%; }
        .box-video .video > a > figure::after {
          background-color: #ffba00;
          border-radius: 50%;
          content: "";
          font-family: 'icomoon' !important;
          font-size: 40px;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          color: #ffffff;
          align-items: center;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          left: calc(50% - 50px);
          top: calc(50% - 50px);
          height: 100px;
          width: 100px; }
      .box-video .video > a > .caption {
        color: #777777;
        font-size: 14px;
        line-height: 1.57143em;
        margin: 5px 0 0; }
      .box-video .video > a:hover > figure::before {
        opacity: 0.8; }
      .box-video .video > a:hover > figure::after {
        background-color: #ffffff;
        color: #ffba00; }
  @media (max-width: 991px) {
    .box-video .video > a > figure::after {
      transform: scale(0.75); } }

.box-share > ul > li > a {
  border-radius: 50%;
  transition: all 0.3s ease; }
  .box-share > ul > li > a:hover {
    background-color: #22509d;
    color: #ffffff; }

.box-downloads {
  margin: 30px 0 0; }
  .box-downloads > ul > li + li {
    margin: 10px 0 0; }
  .box-downloads > ul > li .btn-link > a {
    color: #ffba00;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    font-weight: 600;
    text-align: left;
    text-transform: none;
    white-space: normal;
    width: 100%; }
    .box-downloads > ul > li .btn-link > a > .title {
      width: calc(100% - 90px); }
    .box-downloads > ul > li .btn-link > a > .btn {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      height: 100%;
      text-align: center;
      text-transform: uppercase;
      width: 80px; }
    .box-downloads > ul > li .btn-link > a:hover {
      color: #ffffff; }

.banner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center; }
  .banner > a {
    box-shadow: 0 0 0 1px #e5e5e5;
    display: block;
    position: relative;
    text-align: center;
    width: 100%; }
    .banner > a::before {
      background-color: #000000;
      content: "";
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      transition: all 0.3s ease; }
    .banners-group .banner > a {
      padding: 14px; }
    .banner > a:hover::before {
      opacity: 0.2; }

.box-accordion {
  border: solid 2px #e5e5e5; }
  .box-accordion .accordion-item + .accordion-item {
    border-top: solid 2px #e5e5e5; }
  .box-accordion .accordion-item .accordion-title {
    font-family: "Teko", sans-serif;
    font-size: 24px;
    font-weight: 400;
    cursor: pointer;
    padding: 10px 20px;
    position: relative;
    transition: all 0.3s ease; }
    .box-accordion .accordion-item .accordion-title::before {
      content: "";
      font-family: 'icomoon' !important;
      font-size: 10px;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      color: #777777;
      position: absolute;
      right: 20px;
      top: calc(50% - 6px);
      transform: rotate(0deg);
      height: 12px;
      width: 12px;
      transition: all 0.3s ease;
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center; }
    .box-accordion .accordion-item .accordion-title:hover {
      background-color: #f2f2f2; }
    .box-accordion .accordion-item .accordion-title.active {
      background-color: #ffba00;
      color: #ffffff; }
      .box-accordion .accordion-item .accordion-title.active::before {
        transform: rotate(90deg);
        color: #ffffff; }
  .box-accordion .accordion-item .accordion-content {
    border-top: solid 2px #e5e5e5;
    display: none;
    padding: 15px 20px; }
    .box-accordion .accordion-item .accordion-content > ul > li + li {
      margin: 10px 0 0; }
    .box-accordion .accordion-item .accordion-content > ul > li > a {
      color: #777777;
      display: block;
      font-weight: 600;
      transition: all 0.3s ease; }
      .box-accordion .accordion-item .accordion-content > ul > li > a:hover {
        color: #22509d; }
  @media (max-width: 991px) {
    .box-accordion .accordion-item .accordion-content > ul > li + li {
      margin: 5px 0 0; }
    .box-accordion .accordion-item .accordion-content > ul > li > a {
      background-color: #e5e5e5;
      border-radius: 3px;
      padding: 15px;
      text-align: center; }
      .box-accordion .accordion-item .accordion-content > ul > li > a:hover {
        background-color: #ffba00;
        color: #ffffff; } }

.box-infos-contacts > .item > .link {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  transition: all 0.3s ease; }
  .box-infos-contacts > .item > .link::after {
    content: "";
    font-family: 'icomoon' !important;
    font-size: 10px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color: #22509d;
    margin: 0 0 0 5px;
    transform: translateX(0);
    transition: all 0.3s ease; }
  .box-infos-contacts > .item > .link:hover {
    color: #ffba00; }
    .box-infos-contacts > .item > .link:hover::after {
      color: #ffba00;
      transform: translateX(5px); }

.site-footer .box-infos-contacts {
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.08); }

.buttons-group > .button {
  cursor: pointer;
  transition: all 0.5s ease; }
  .buttons-group > .button:hover {
    background-color: #ffba00;
    color: #ffffff; }

@media (min-width: 992px) {
  .buttons-group > .button::before, .buttons-group > .button::after {
    background-color: #ffba00;
    content: "";
    pointer-events: none;
    position: absolute;
    height: 100%;
    width: 100%;
    transform: rotate(-25deg);
    transition: all 0.5s ease; }
  .buttons-group > .button::before {
    right: 50%;
    bottom: 50%;
    transform-origin: 50% 50%; }
  .buttons-group > .button::after {
    left: 50%;
    top: 50%;
    transform-origin: 50% 50%; }
  .buttons-group > .button:hover::before {
    right: 100%;
    bottom: 100%; }
  .buttons-group > .button:hover::after {
    left: 100%;
    top: 100%; } }

.field-group > .field {
  transition: all 0.3s ease; }
  .field-group > .field:focus {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.12); }

.field-group > p {
  color: #cc0000;
  cursor: default;
  display: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2em;
  margin: 5px 0 0;
  position: absolute;
  right: 15px;
  top: 100%; }

.field-group.errorField > .field {
  border-color: #cc0000;
  color: #cc0000; }

.field-group.errorField > p {
  display: block; }

.home-slide .owl-carousel .slide-item > a {
  transition: all 0.3s ease; }
  .home-slide .owl-carousel .slide-item > a:hover {
    background-color: rgba(0, 0, 0, 0.2); }

.home-slide .owl-carousel .owl-nav > span {
  border: solid 4px #8d95ab;
  border-radius: 50%;
  display: block;
  height: 60px;
  width: 60px;
  transition: all 0.3s ease; }
  .home-slide .owl-carousel .owl-nav > span::before {
    content: "";
    font-family: 'icomoon' !important;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color: #ffba00;
    position: absolute;
    left: 0;
    top: 0;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
    width: 100%;
    transition: all 0.3s ease; }
  .home-slide .owl-carousel .owl-nav > span > span {
    display: none; }
  .home-slide .owl-carousel .owl-nav > span.owl-prev::before {
    content: "\e902"; }
  .home-slide .owl-carousel .owl-nav > span.owl-next::before {
    content: "\e903"; }
  .home-slide .owl-carousel .owl-nav > span:hover {
    border-color: #ffba00; }
    .home-slide .owl-carousel .owl-nav > span:hover::before {
      color: #ffffff; }

@media (min-width: 1440px) {
  .home-slide .owl-carousel .owl-nav > span {
    position: absolute;
    top: calc(50% - 30px); }
    .home-slide .owl-carousel .owl-nav > span.owl-prev {
      left: 20px; }
    .home-slide .owl-carousel .owl-nav > span.owl-next {
      right: 20px; } }

@media (max-width: 1439px) {
  .home-slide .owl-carousel .owl-nav {
    position: absolute;
    left: 0;
    bottom: 15px;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center; }
    .home-slide .owl-carousel .owl-nav > span {
      position: relative; }
      .home-slide .owl-carousel .owl-nav > span + span {
        margin: 0 0 0 20px; } }

@media (max-width: 991px) {
  .home-slide .owl-carousel .owl-nav {
    display: none; } }

.home-headlines .box-right .owl-navs > span {
  border: solid 4px #22509d;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  position: relative;
  height: 60px;
  width: 60px;
  transition: all 0.3s ease; }
  .home-headlines .box-right .owl-navs > span::before {
    content: "";
    font-family: 'icomoon' !important;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color: #22509d;
    position: absolute;
    left: 0;
    top: 0;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
    width: 100%;
    transition: all 0.3s ease; }
  .home-headlines .box-right .owl-navs > span > span {
    display: none; }
  .home-headlines .box-right .owl-navs > span.owl-prev::before {
    content: "\e902"; }
  .home-headlines .box-right .owl-navs > span.owl-next::before {
    content: "\e903"; }
  .home-headlines .box-right .owl-navs > span:hover {
    border-color: #ffffff; }
    .home-headlines .box-right .owl-navs > span:hover::before {
      color: #ffffff; }

@media (min-width: 992px) {
  .home-headlines .box-right::before {
    background-image: url(../images/backgrounds/bg-manchetes.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 745px;
    z-index: -1; }
  .home-headlines .box-right .owl-navs {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; }
    .home-headlines .box-right .owl-navs > span + span {
      margin: 20px 0 0; } }

@media (max-width: 991px) {
  .home-headlines .box-right .owl-navs > span:hover {
    border-color: #ffba00; }
    .home-headlines .box-right .owl-navs > span:hover::before {
      color: #ffba00; } }

.home-links ul > li > a {
  transition: all 0.3s ease; }
  .home-links ul > li > a .inner > i {
    transition: all 0.3s ease; }
  .home-links ul > li > a .inner > .title {
    position: relative;
    transition: all 0.3s ease; }
    .home-links ul > li > a .inner > .title::before {
      background-color: #e5e5e5;
      content: "";
      position: absolute;
      left: -20px;
      top: 0;
      opacity: 1;
      height: 100%;
      width: 2px;
      transition: all 0.3s ease; }
  .home-links ul > li > a .inner > .link {
    transition: all 0.3s ease; }
    .home-links ul > li > a .inner > .link::after {
      content: "";
      font-family: 'icomoon' !important;
      font-size: 10px;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      color: #ffba00;
      display: block;
      margin: 0 0 0 10px;
      transform: translateX(0);
      transition: all 0.3s ease; }
  .home-links ul > li > a:hover {
    background-color: #22509d;
    color: #ffffff; }
    .home-links ul > li > a:hover .inner > i {
      color: #ffffff; }
    .home-links ul > li > a:hover .inner > .title {
      color: #ffffff; }
      .home-links ul > li > a:hover .inner > .title::before {
        opacity: 0.1; }
    .home-links ul > li > a:hover .inner > .link {
      color: #ffffff; }
      .home-links ul > li > a:hover .inner > .link::after {
        color: #ffffff;
        transform: translateX(5px); }

.cta-actions {
  background-color: #22509d;
  padding: 30px 0; }
  .cta-actions .container > .row {
    min-height: 140px; }
  .cta-actions .title {
    color: #ffba00;
    font-size: 42px;
    line-height: 1em;
    text-transform: uppercase; }
  .cta-actions .content .inner {
    position: relative; }
  .cta-actions .content p {
    color: #ffffff;
    font-size: 16px;
    line-height: 1.375em; }
  .cta-actions .content .btn-link {
    margin: 0 0 0 20px; }
  @media (min-width: 992px) {
    .cta-actions {
      background-image: url(../images/backgrounds/bg-action.jpg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover; }
      .cta-actions .content .inner {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        padding-bottom: 4px;
        border-bottom: solid 1px #e5e5e5; }
        .cta-actions .content .inner::after {
          background-color: #ffba00;
          bottom: -2px;
          content: "";
          left: 0;
          position: absolute;
          height: 4px;
          width: 80px; } }
  @media (max-width: 991px) {
    .cta-actions {
      margin-left: 15px;
      margin-right: 15px; }
      .cta-actions .title {
        text-align: center;
        margin: 0 0 20px;
        width: 100%; }
      .cta-actions .content {
        width: 100%; }
        .cta-actions .content .inner {
          align-items: center;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          text-align: center; }
          .cta-actions .content .inner .btn-link {
            margin: 20px 0 0; } }

.home-banners .banners-group > .row {
  margin-bottom: -40px; }

.home-banners .banners-group .banner {
  margin: 0 0 40px; }

@media (max-width: 991px) {
  .home-banners .banner {
    width: 100%; }
  .home-banners .banners-group {
    margin: 20px 0 0;
    width: 100%; }
    .home-banners .banners-group > .row {
      margin-bottom: 0; }
    .home-banners .banners-group .banner {
      margin: 0; }
      .home-banners .banners-group .banner + .banner {
        margin: 20px 0 0; } }

.page-about .box-mission {
  background-color: #22509d;
  margin: 40px 0 0;
  padding: 50px; }
  .page-about .box-mission .title {
    color: #ffba00;
    font-size: 42px;
    line-height: 1em;
    margin: 0 0 10px;
    text-transform: uppercase; }
  .page-about .box-mission .banner {
    justify-content: flex-start; }
    .page-about .box-mission .banner > a {
      width: auto; }
  .page-about .box-mission .box-text {
    color: #ffffff; }

@media (min-width: 992px) {
  .page-about .box-mission {
    padding: 65px 55px;
    position: relative;
    z-index: 1; }
    .page-about .box-mission::before {
      background-image: url(../images/backgrounds/bg-mission.jpg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 1245px;
      z-index: -1; } }

@media (max-width: 767px) {
  .page-about .box-mission {
    padding: 20px; }
    .page-about .box-mission .row {
      align-items: center;
      flex-direction: column; }
    .page-about .box-mission div[class*="grid-"] {
      text-align: center;
      width: auto; }
      .page-about .box-mission div[class*="grid-"] + div {
        margin: 20px 0 0; } }

.page-directors .page-subtitle {
  font-size: 32px;
  font-weight: 400;
  line-height: 1em;
  margin: 0 0 30px;
  text-align: center; }

.page-directors .list-items > .row {
  margin-bottom: -50px; }

.page-directors .item {
  margin: 0 0 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center; }
  .page-directors .item > .inner {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; }
    .page-directors .item > .inner > img {
      margin: 0 0 20px; }
    .page-directors .item > .inner > .title {
      font-family: "Montserrat", sans-serif;
      font-size: 16px; }
    .page-directors .item > .inner > .role {
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      margin: 5px 0 0; }
    .page-directors .item > .inner > .box-text {
      margin: 5px 0 0; }
      .page-directors .item > .inner > .box-text > p + p {
        margin: 2px 0 0; }

.page-directors .audit-committee {
  border-top: solid 1px #e5e5e5;
  margin: 40px 0 0;
  padding: 40px 0 0; }
  .page-directors .audit-committee .item > .inner {
    border-top: solid 1px #e5e5e5;
    padding: 50px 0 0;
    width: 100%; }
  .page-directors .audit-committee .item:nth-child(1) > .inner, .page-directors .audit-committee .item:nth-child(2) > .inner {
    border: none;
    padding: 0; }

@media (min-width: 1200px) {
  .page-directors .audit-committee {
    padding: 40px 0 0 85px; } }

@media (max-width: 575px) {
  .page-directors .item {
    width: 100%; } }

.page-covenants .list-items > .row {
  justify-content: center;
  margin-bottom: -30px; }

.page-covenants .item {
  margin: 0 0 30px;
  padding: 0 15px;
  width: 20%; }
  .page-covenants .item > a {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
    transition: all 0.3s ease; }
    .page-covenants .item > a > img {
      margin: 0 0 20px; }
    .page-covenants .item > a > .title {
      color: #777777;
      font-size: 28px;
      font-weight: 400;
      transition: all 0.3s ease; }

.page-covenants .banner-covenants {
  background-color: #486dad;
  margin: 120px 0 0;
  padding: 50px 35px;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center; }
  .page-covenants .banner-covenants > .title {
    color: #ffba00;
    font-size: 40px;
    font-weight: 400;
    padding: 0 15px;
    width: 50%; }
  .page-covenants .banner-covenants > .content {
    color: #ffffff;
    padding: 0 15px;
    width: 50%; }
    .page-covenants .banner-covenants > .content > .phone {
      font-size: 20px;
      font-weight: 700;
      line-height: 1.2em;
      text-transform: uppercase; }
      .page-covenants .banner-covenants > .content > .phone > strong {
        font-size: 30px;
        line-height: 1.2em; }
    .page-covenants .banner-covenants > .content > .text {
      font-size: 17px;
      line-height: 1.2em;
      margin: 5px 0 0; }

@media (min-width: 992px) {
  .page-covenants .item > a {
    transform: translateY(0); }
    .page-covenants .item > a:hover {
      transform: translateY(-15px); }
      .page-covenants .item > a:hover > .title {
        color: #22509d; }
  .page-covenants .banner-covenants {
    background-image: url(../images/backgrounds/bg-indica-convenio.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; } }

@media (max-width: 991px) {
  .page-covenants .item {
    width: 100%; }
    .page-covenants .item > a {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      padding: 20px; }
      .page-covenants .item > a:hover {
        background-color: #fafafa; }
  .page-covenants .banner-covenants {
    align-items: center;
    flex-direction: column;
    padding: 20px; }
    .page-covenants .banner-covenants .title,
    .page-covenants .banner-covenants .content {
      text-align: center;
      width: auto; }
    .page-covenants .banner-covenants .title {
      margin: 0 0 20px; }
    .page-covenants .banner-covenants .content > .phone > strong {
      display: block; } }

.page-services .list-items > .row {
  justify-content: center;
  margin-bottom: -30px; }

.page-services .item {
  margin: 0 0 30px; }
  .page-services .item > a {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
    transition: all 0.3s ease; }
    .page-services .item > a > img {
      margin: 0 0 20px; }
    .page-services .item > a > .title {
      color: #777777;
      font-size: 28px;
      font-weight: 400;
      transition: all 0.3s ease; }

@media (min-width: 992px) {
  .page-services .item > a {
    transform: translateY(0); }
    .page-services .item > a:hover {
      transform: translateY(-15px); }
      .page-services .item > a:hover > .title {
        color: #22509d; } }

@media (max-width: 991px) {
  .page-services .item {
    width: 100%; }
    .page-services .item > a {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      padding: 20px; }
      .page-services .item > a:hover {
        background-color: #fafafa; } }

.service-detail-model .page-title {
  font-size: 32px;
  font-weight: 400;
  line-height: 1em;
  margin: 0 0 20px; }

.page-covenants-category .list-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.page-covenants-category .item {
  margin: -0.5px;
  width: 25%; }
  .page-covenants-category .item > a {
    border: solid 1px #e5e5e5;
    display: block;
    padding: 40px 25px;
    position: relative;
    text-align: center; }
    .page-covenants-category .item > a::before {
      background-color: rgba(0, 0, 0, 0.03);
      border: solid 5px #22509d;
      content: "";
      left: -1px;
      top: -1px;
      position: absolute;
      height: calc(100% + 2px);
      width: calc(100% + 2px);
      opacity: 0;
      transition: all 0.5s ease; }
    .page-covenants-category .item > a:hover::before {
      opacity: 1; }

@media (max-width: 991px) {
  .page-covenants-category .item {
    width: 33.33%; } }

@media (max-width: 767px) {
  .page-covenants-category .item {
    width: 50%; } }

@media (max-width: 575px) {
  .page-covenants-category .item {
    width: 100%; } }

.page-covenants-detail .page-content > .box-text {
  margin: 50px 0 0; }

.page-covenants-detail .image {
  width: 44.25287%;
  padding: 0 15px; }
  .page-covenants-detail .image > figure {
    border: solid 1px #e5e5e5;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 60px 25px; }

.page-covenants-detail .contacts {
  padding: 0 15px;
  width: 55.74713%; }
  .page-covenants-detail .contacts .page-subtitle {
    font-size: 32px;
    font-weight: 400;
    line-height: 1em;
    margin: 0 0 20px; }
  .page-covenants-detail .contacts > .box-text > p + p {
    margin: 3px 0 0; }

@media (max-width: 991px) {
  .page-covenants-detail .image {
    margin: 0 0 30px;
    width: 100%; }
  .page-covenants-detail .contacts {
    width: 100%; }
  .page-covenants-detail .page-content > .box-text {
    margin: 30px 0 0; } }

.news-detail-model .page-content > .box-text {
  margin: 30px 0 0; }

.news-detail-model .image {
  width: 44.25287%;
  padding: 0 15px; }
  .news-detail-model .image > figure {
    border: solid 1px #e5e5e5;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 60px 25px; }

.news-detail-model .introduction {
  padding: 0 15px;
  width: 55.74713%; }
  .news-detail-model .introduction .item-date {
    background-color: #22509d;
    color: #ffffff;
    display: table;
    font-size: 14px;
    font-weight: 600;
    line-height: 1em;
    text-transform: uppercase;
    padding: 8px 20px;
    margin: 0 0 20px; }
  .news-detail-model .introduction .item-title {
    font-size: 18px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    line-height: 1.33333em;
    margin: 0 0 20px; }
  .news-detail-model .introduction > .box-text > p + p {
    margin: 3px 0 0; }

@media (max-width: 991px) {
  .news-detail-model .image {
    margin: 0 0 30px;
    width: 100%; }
  .news-detail-model .introduction {
    width: 100%; }
  .news-detail-model .page-content > .box-text {
    margin: 30px 0 0; } }

.page-links-uteis .category + .category {
  margin: 40px 0 0; }

.page-links-uteis .category .box-text > ul > li::before {
  top: 4px; }

.page-links-uteis .category .box-text > ul > li > a {
  color: #777777;
  display: table; }
  .page-links-uteis .category .box-text > ul > li > a:hover {
    color: #ffba00; }

.page-links-uteis .category .page-subtitle {
  font-size: 32px;
  font-weight: 400;
  line-height: 1em;
  margin: 0 0 20px; }

@media (min-width: 992px) {
  .page-links-uteis .category.column .box-text > ul {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .page-links-uteis .category.column .box-text > ul > li {
      margin: 0 0 10px;
      padding: 0 15px;
      width: 50%; } }

@media (max-width: 991px) {
  .page-links-uteis .category .page-subtitle {
    text-align: center; }
  .page-links-uteis .category .box-text > ul {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -2.5px -5px; }
    .page-links-uteis .category .box-text > ul > li {
      padding: 0 2.5px;
      margin: 0 0 5px;
      width: 33.33%; }
      .page-links-uteis .category .box-text > ul > li::before {
        display: none; }
      .page-links-uteis .category .box-text > ul > li > a {
        background-color: #e5e5e5;
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding: 15px 20px;
        text-align: center;
        height: 100%; }
        .page-links-uteis .category .box-text > ul > li > a:hover {
          background-color: #ffba00;
          color: #ffffff; } }

@media (max-width: 767px) {
  .page-links-uteis .category .box-text > ul > li {
    width: 50%; } }

@media (max-width: 575px) {
  .page-links-uteis .category .box-text > ul > li {
    width: 100%; } }

.page-president-schedule .page-subtitle {
  font-size: 32px;
  font-weight: 400;
  line-height: 1em;
  margin: 0 0 20px; }

.page-president-schedule .box-news {
  margin: 50px 0 0; }

.page-president-schedule .schedule-filter > .title {
  color: #ffba00;
  font-size: 42px; }

.page-president-schedule .schedule-filter > .box-accordion {
  margin: 40px 0 0; }

@media (min-width: 992px) {
  .page-president-schedule .box-news {
    max-width: 770px; } }
