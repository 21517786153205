.card-photo {
    @include flex(
        $justify: center
    );
    margin: 0 0 30px;
    > a {
        background-color: $brand-color;
        display: block;
        height: 100%;
        position: relative;
        @include transition;
        .-light & {
            background-color: #ffffff;
        }
        > figure {
            overflow: hidden;
            position: relative;
            &::before,
            &::after {
                content: "";
                position: absolute;
                @include transition;
                opacity: 0;
            }
            &::before {
                background-color: #000000;
                left: 0;
                opacity: 0;
                top: 0;
                @include size(100%);
                z-index: 1;
            }
            &::after {
                background-color: $brand-color-secondary;
                border-radius: 50%;
                @include iconeBg(
                    $content: "\e91a",
                    $fontSize: 30px
                );
                color: #ffffff;
                @include flex(
                    $align: center,
                    $justify: center
                );
                left: calc(50% - 32px);
                top: 100%;
                @include size(64px);
                z-index: 2;
            }
        }
        >.title {
            color: $brand-color-secondary;
            font-size: 26px;
            font-weight: weight(regular, secondary);
            line-height: em(26, 26);
            padding: 15px 15px 12px;
            text-align: center;
            text-transform: uppercase;
            @include transition;
        }
        &:hover {
            background-color: $brand-color-secondary;
            > figure {
                &::before,
                &::after {
                    opacity: 1;
                }
                &::before {
                    opacity: 0.8;
                }
                &::after {
                    top: calc(50% - 32px);
                }
            }
            >.title {
                color: #ffffff;
            }
        }
    }
    .page-physical-structure & {
        > a {
            max-width: 370px;
            margin: 0 auto;
        }
    }

    @include breakpoint-max(xlarge) {
        width: 33.33%;
        .page-physical-structure & {
            width: 50%;
        }
    }
    @include breakpoint-max(large) {
        width: 100%;
        > a {
            >.title {
                font-size: 20px;
            }
        }
    }
}