.site-footer {
    // Links Footer
    .links-footer {
        >.webmail {
            @include transition;
            &:hover {
                background-color: $brand-color-secondary;
            }
        }
        >.social-media {
            > li {
                > a {
                    @include transition;
                    > i {
                        @include transition(
                            $duration: 0.6s
                        );
                    }
                    &:hover {
                        color: $brand-color-secondary;
                        > i {
                            background-color: $brand-color-secondary;
                        }
                    }
                }
            }
        }
    }

    // Utils Links
    .utils-links {
        > ul {
            > li {
                > a {
                    @include transition;
                    transform: translateX(0);
                    &:hover {
                        color: $brand-color-secondary;
                        transform: translateX(5px)
                    }
                }
            }
        }
    }

    // Contacts
    .contacts {
        .back-top {
            > a {
                @include transition;
                &::after {
                    @include iconeBg(
                        $content: "\e908",
                        $fontSize: 8px
                    );
                    color: $brand-color-secondary;
                    display: block;
                    margin: 3px 0 0 12px;
                    @include transition;
                    transform: translateY(0);
                }
                &:hover {
                    color: $brand-color-secondary;
                    &::after {
                        transform: translateY(-5px);
                    }
                }
            }
        }
    }

    // Bottom
    .bottom {
        >.tdh {
            @include transition;
            &:hover {
                color: $brand-color;
            }
        }
    }

    @include breakpoint-max(large) {
        // Utils Links
        .utils-links {
            > ul {
                > li {
                    > a {
                        transform: translate(0) !important;
                        &:hover {
                            background-color: $brand-color-secondary;
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }
}