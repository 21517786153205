.page-directors {
    .page-subtitle {
        font-size: 32px;
        font-weight: weight(regular, secondary);
        line-height: em(32, 32);
        margin: 0 0 30px;
        text-align: center;
    }
    .list-items {
        >.row {
            margin-bottom: -50px;
        }
    }
    .item {
        margin: 0 0 50px;
        @include flex(
            $justify: center
        );
        >.inner {
            @include flex(
                $direction: column,
                $align: flex-start
            );
            > img {
                margin: 0 0 20px;
            }
            >.title {
                @include font-primary(
                    $size: 16px,
                    $weight: weight(bold)
                );
            }
            >.role {
                @include font-primary(
                    $size: 14px,
                    $weight: weight(bold)
                );
                margin: 5px 0 0;
            }
            >.box-text {
                margin: 5px 0 0;
                > p {
                    & + p {
                        margin: 2px 0 0;
                    }
                }
            }
        }
    }
    .audit-committee {
        border-top: solid 1px $border-color;
        margin: 40px 0 0;
        padding: 40px 0 0;
        .item {
            >.inner {
                border-top: solid 1px $border-color;
                padding: 50px 0 0;
                width: 100%;
            }
            &:nth-child(1),
            &:nth-child(2) {
                >.inner {
                    border: none;
                    padding: 0;
                }
            }
        }
    }
    @include breakpoint-min(xlarge) {
        .audit-committee {
            padding: 40px 0 0 85px;
        }
    }
    @include breakpoint-max(small) {
        .item {
            width: 100%;
        }
    }
}