.breadcrumbs {
    ol {
        &::before,
        &::after {
            content: "";
            position: absolute;
        }
        &::before {
            background-color: $brand-color;
            @include size(1000%, 100%);
            right: 100%;
            top: 0;
            z-index: -1;
        }
        &::after {
            background-color: #ffffff;
            @include size(1000%, 2px);
            right: 0;
            top: calc(50% - 1px);
            z-index: 1;
        }
        > li {
            & + li {
                &::before {
                    content: "";
                }
            }
            > a {
                @include transition;
                &:hover {
                    color: $brand-color-secondary;
                }
            }
        }
    }
}