.page-covenants-detail {
    .page-content {
        >.box-text {
            margin: 50px 0 0;
        }
    }
    .image {
        width: percent(385, 870);
        padding: 0 15px;
        > figure {
            border: solid 1px $border-color;
            @include flex(
                $align: center,
                $justify: center
            );
            padding: 60px 25px;
        }
    }
    .contacts {
        padding: 0 15px;
        width: percent(485, 870);
        .page-subtitle {
            font-size: 32px;
            font-weight: weight(regular, secondary);
            line-height: em(32, 32);
            margin: 0 0 20px;
        }
        >.box-text {
            > p {
                & + p {
                    margin: 3px 0 0;
                }
            }
        }
    }
    @include breakpoint-max(large) {
        .image {
            margin: 0 0 30px;
            width: 100%;
        }
        .contacts {
            width: 100%;
        }
        .page-content {
            >.box-text {
                margin: 30px 0 0;
            }
        }
    }
}