.news-detail-model {
    .page-content {
        >.box-text {
            margin: 30px 0 0;
        }
    }
    .image {
        width: percent(385, 870);
        padding: 0 15px;
        > figure {
            border: solid 1px $border-color;
            @include flex(
                $align: center,
                $justify: center
            );
            padding: 60px 25px;
        }
    }
    .introduction {
        padding: 0 15px;
        width: percent(485, 870);
        .item-date{
            background-color: $brand-color;
            color: #ffffff;
            display: table;
            font-size: 14px;
            font-weight: weight(semibold);
            line-height: em(14, 14);
            text-transform: uppercase;
            padding: 8px 20px;
            margin: 0 0 20px;
        }
        .item-title {
            font-size: 18px;
            @include font-primary(
                $weight: bold
            );
            line-height: em(24, 18px);
            margin: 0 0 20px;
        }
        >.box-text {
            > p {
                & + p {
                    margin: 3px 0 0;
                }
            }
        }
    }
    @include breakpoint-max(large) {
        .image {
            margin: 0 0 30px;
            width: 100%;
        }
        .introduction {
            width: 100%;
        }
        .page-content {
            >.box-text {
                margin: 30px 0 0;
            }
        }
    }
}