.box-title {
    position: relative;
    z-index: 1;
    >.title {
        position: relative;
        &::before {
            @include iconeBg(
                $content: "\e918",
                $fontSize: 85px
            );
            color: #ececec;
            display: block;
            margin: 0 -105px 0 0;
            @include size(170px, 90px);
            position: relative;
            transform: translateY(-8px);
            z-index: -1;
        }
    }
    &.-secondary {
        >.title {
            &::before {
                color: #000000;
                opacity: 0.15;
            }
        }
    }
    &.-subtitle {
        >.title {
            &::before {
                @include size(120px, 60px);
                font-size: 60px;
                margin: 0 -70px 0 0;
            }
        }
    }

    @include breakpoint-max(large) {
        >.title {
            &::before {
                font-size: 50px;
                @include size(100px, 50px);
                margin: 0 -50px 0 0;
            }
        }
    }

    @include breakpoint-max(small) {
        >.title {
            padding: 15px 0 0;
            &::before {
                position: absolute;
                top: 0;
                left: calc(50% - 50px);
                transform: translateY(0);
            }
        }
    }
}