@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?grrme1');
    src:  url('../fonts/icomoon.eot?grrme1#iefix') format('embedded-opentype'),
      url('../fonts/icomoon.ttf?grrme1') format('truetype'),
      url('../fonts/icomoon.woff?grrme1') format('woff'),
      url('../fonts/icomoon.svg?grrme1#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-arrow-up:before {
    content: "\e900";
  }
  .icon-arrow-down:before {
    content: "\e901";
  }
  .icon-arrow-left:before {
    content: "\e902";
  }
  .icon-arrow-right:before {
    content: "\e903";
  }
  .icon-long-arrow-up:before {
    content: "\e904";
  }
  .icon-long-arrow-down:before {
    content: "\e905";
  }
  .icon-long-arrow-left:before {
    content: "\e906";
  }
  .icon-long-arrow-right:before {
    content: "\e907";
  }
  .icon-caret-top:before {
    content: "\e908";
  }
  .icon-caret-down:before {
    content: "\e909";
  }
  .icon-caret-left:before {
    content: "\e90a";
  }
  .icon-caret-right:before {
    content: "\e90b";
  }
  .icon-chevron-line-up:before {
    content: "\e90c";
  }
  .icon-chevron-line-down:before {
    content: "\e90d";
  }
  .icon-chevron-line-left:before {
    content: "\e90e";
  }
  .icon-chevron-line-right:before {
    content: "\e90f";
  }
  .icon-chevron-up:before {
    content: "\e910";
  }
  .icon-chevron-down:before {
    content: "\e911";
  }
  .icon-chevron-left:before {
    content: "\e912";
  }
  .icon-chevron-right:before {
    content: "\e913";
  }
  .icon-add:before {
    content: "\e914";
  }
  .icon-close:before {
    content: "\e915";
  }
  .icon-check:before {
    content: "\e916";
  }
  .icon-play-button-sing:before {
    content: "\e917";
  }
  .icon-shield:before {
    content: "\e918";
  }
  .icon-road:before {
    content: "\e919";
  }
  .icon-zoom:before {
    content: "\e91a";
  }
  .icon-download:before {
    content: "\e91b";
  }
  .icon-hours:before {
    content: "\e91c";
  }
  .icon-calendar:before {
    content: "\e91d";
  }
  .icon-check-in:before {
    content: "\e91e";
  }
  .icon-contact-book:before {
    content: "\e91f";
  }
  .icon-gdpr:before {
    content: "\e920";
  }
  .icon-id-card:before {
    content: "\e921";
  }
  .icon-law-book:before {
    content: "\e922";
  }
  .icon-skyline:before {
    content: "\e923";
  }
  .icon-smartphone:before {
    content: "\e924";
  }
  .icon-support:before {
    content: "\e925";
  }
  .icon-tag:before {
    content: "\e926";
  }
  .icon-team:before {
    content: "\e927";
  }
  .icon-clock:before {
    content: "\e928";
  }
  .icon-envelope:before {
    content: "\e929";
  }
  .icon-paper-plane:before {
    content: "\e92a";
  }
  .icon-placeholder:before {
    content: "\e92b";
  }
  .icon-telephone:before {
    content: "\e92c";
  }
  .icon-whatsapp:before {
    content: "\e92d";
  }
  .icon-facebook:before {
    content: "\e92e";
  }
  .icon-instagram:before {
    content: "\e92f";
  }
  .icon-twitter:before {
    content: "\e930";
  }
  .icon-youtube-play:before {
    content: "\e931";
  }
  .icon-flickr:before {
    content: "\e932";
  }
  .icon-linkedin:before {
    content: "\e933";
  }
  .icon-tdh-logo:before {
    content: "\e934";
  }
  