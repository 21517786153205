// Generator Keyframes
@mixin keyframes($name) {
	@keyframes #{$name} {
		@content;
	}
}

// FlexBox
@mixin flex(
    $align: false,
    $justify: false,
    $direction: row,
    $wrap: wrap
) {
	@if($align) {
		align-items: $align;
	}
	display: flex;
	@if($direction) {
		flex-direction: $direction;
	}
	flex-wrap: $wrap;
	
	@if($justify) {
		justify-content: $justify;
	}
}

// Helper to size an element
// @author Hugo Giraudel
// @param {Length} $width
// @param {Length} $height
@mixin size($width, $height: $width) {
	height: $height;
	width: $width;
}

// Helper to import images
@mixin imageBg(
	$image: false,
	$pos: center center,
	$size: false,
	$repeat: no-repeat
) {
	@if($image) {
		background-image: url(../images/backgrounds/#{$image});
	}
	background-position: $pos;
	background-repeat: $repeat;
	@if($size) {
		background-size: $size;
	}
}

// Transition
@mixin transition(
	$prop: all,
	$duration: 0.3s,
	$function: ease
) {
	transition: $prop $duration $function;
}

// Carregar Icone BG (Icomoon)
@mixin iconeBg(
    $content: "",
    $fontSize: 16px
) {
    content: $content;
    font-family: 'icomoon' !important;
    font-size: $fontSize;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
}

// Mixin Font Primary
// @required $weight
// @optional $size
// @optional $lineHeight (in pixel or only number)
@mixin font-primary(
	$weight: false,
	$size: false,
	$lineHeight: false
) {
	@if($weight != false) {
		font-family: $font-primary;

		@if($size) {
			@if (unitless($size)) {
				$size: $size * 1px;
			}
			
			font-size: $size;
		}

		@if map-has-key($font-primary-weight, $weight) {
			font-weight: #{inspect(map-get($font-primary-weight, $weight))};
		}

		@if($lineHeight) {
			@if($size) {
				line-height: em($lineHeight, $size);
			} @else {
				line-height: em($lineHeight);
			}
		}
	} @else {
		@warn "Informe um valor para `$weight`. Weights disponiveis: #{map-keys($font-primary-weight)}";
	}
}

// Mixin Font Secondary
// @required $weight
// @optional $size
// @optional $lineHeight (in pixel or only number)
@mixin font-secondary(
	$weight: false,
	$size: false,
	$lineHeight: false
) {
	@if($weight != false) {
		font-family: $font-secondary;

		@if($size) {
			@if (unitless($size)) {
				$size: $size * 1px;
			}

			font-size: $size;
		}

		@if map-has-key($font-secondary-weight, $weight) {
			font-weight: #{inspect(map-get($font-secondary-weight, $weight))};
		}

		@if($lineHeight) {
			@if($size) {
				line-height: em($lineHeight, $size);
			} @else {
				line-height: em($lineHeight);
			}
		}
	} @else {
		@warn "Informe um valor para `$weight`. Weights disponiveis: #{map-keys($font-secondary-weight)}";
	}
}

// Mixin to manage responsive breakpoints
// @require $breakpoints-max
@mixin breakpoint-max($breakpoint: false) {
	@if($breakpoint != false) {
		@if map-has-key($breakpoints-max, $breakpoint) {
			@media #{inspect(map-get($breakpoints-max, $breakpoint))} {
				@content;
			}
		} @else if($breakpoint) {
			@media (max-width: $breakpoint) {
				@content;
			}
		} @else {
			@warn "Nenhum valor encontrado para `#{$breakpoint}`. " + "Breakpoints disponiveis: #{map-keys($breakpoints-max)}.";
		}
		
	} @else {
		@warn "Informe um valor para `$breakpoint`. Breakpoints disponiveis: #{map-keys($breakpoints-max)} ou um valor customizado em px (Ex.: 767px)";
	}
}

// Mixin to manage responsive breakpoints
// @require $breakpoints-min
@mixin breakpoint-min($breakpoint: false) {
	@if($breakpoint != false) {
		@if map-has-key($breakpoints-min, $breakpoint) {
			@media #{inspect(map-get($breakpoints-min, $breakpoint))} {
				@content;
			}
		} @else if($breakpoint) {
			@media (min-width: $breakpoint) {
				@content;
			}
		} @else {
			@warn "Nenhum valor encontrado para `#{$breakpoint}`. " + "Breakpoints disponiveis: #{map-keys($breakpoints-min)}.";
		}
		
	} @else {
		@warn "Informe um valor para `$breakpoint`. Breakpoints disponiveis: #{map-keys($breakpoints-min)} ou um valor customizado em px (Ex.: 767px)";
	}
}

// Placehlders
@mixin placeholder {
	&::-webkit-input-placeholder {@content}
	&:-moz-placeholder {@content}
	&::-moz-placeholder {@content}
	&:-ms-input-placeholder {@content}
}