.field-group {
    >.field {
        @include transition;
        &:focus {
            box-shadow: 0 0 15px rgba(#000000, 0.12);
        }
    }
    > p {
        color: $error;
        cursor: default;
        display: none;
        font-size: 12px;
        font-weight: weight(regular);
        line-height: 1.2em;
        margin: 5px 0 0;
        position: absolute;
        right: 15px;
        top: 100%;
    }
    &.errorField {
        >.field {
            border-color: $error;
            color: $error;
        }
        > p {
            display: block;
        }
    }
}