.box-infos-contacts {
    >.item {
        >.link {
            @include flex(
                $align: center
            );
            @include transition;
            &::after {
                @include iconeBg(
                    $content: "\e903",
                    $fontSize: 10px
                );
                color: $brand-color;
                margin: 0 0 0 5px;
                transform: translateX(0);
                @include transition;
            }
            &:hover {
                color: $brand-color-secondary;
                &::after {
                    color: $brand-color-secondary;
                    transform: translateX(5px);
                }
            }
        }
    }
    .site-footer & {
        box-shadow: 0 8px 15px rgba(#000000, 0.08);
    }
}