.box-gallery-photos {
    background-color: #dad9d8;
    padding: 40px 0 60px;
    width: 100%;
    .box-title {
        >.title {
            color: $brand-color;
            &::before {
                color: #ced2d5;
            }
        }
    }
    .list-items {
        >.row {
            margin-bottom: -30px;
            justify-content: center;
        }
    }
    .btn-link {
        margin: 50px 0 0;
    }
    @include breakpoint-min(large) {
        @include imageBg(
            $image: "bg-image.jpg",
            $size: cover
        );
    }
    @include breakpoint-max(large) {
        background-image: linear-gradient(45deg, #dad9d8, lighten(#dad9d8, 12%));
    }
}