.box-gallery-video {
    background-color: darken($brand-color, 10%);
    margin: 0 auto;
    max-width: 1920px;
    padding: 40px 0 60px;
    width: 100%;
    &.-home {
        margin-top: 60px;
    }
    .list-items {
        >.row {
            margin-bottom: -30px;
            justify-content: center;
        }
    }
    .btn-link {
        margin: 50px 0 0;
    }
    @include breakpoint-min(large) {
        @include imageBg(
            $image: "bg-video.jpg",
            $size: cover
        );
    }
    @include breakpoint-max(large) {
        background-image: linear-gradient(45deg, darken($brand-color, 10%), $brand-color);
    }
}