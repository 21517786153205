.box-search {
    .form-search {
        .form-field {
            @include placeholder {
                color: $text-color-light;
                opacity: 1;
            };
            @include transition;
            &:focus {
                box-shadow: 0 0 15px rgba(#000000, 0.15);
            }
        }
        .form-button {
            cursor: pointer;
            text-align: left;
            text-indent: -9999px;
            @include transition;
            &::before {
                @include iconeBg(
                    $content: "\e91a",
                    $fontSize: 20px
                );
                color: #ffffff;
                @include flex(
                    $align: center,
                    $justify: center
                );
                left: 0;
                top: 0;
                position: absolute;
                text-indent: 0;
                @include size(100%);
            }
            &:hover {
                background-color: $brand-color-secondary;
            }
        }
    }
}