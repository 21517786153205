.page-president-schedule {
    .page-subtitle {
        font-size: 32px;
        font-weight: weight(regular, secondary);
        line-height: em(32, 32);
        margin: 0 0 20px;
    }
    .box-news {
        margin: 50px 0 0;
    }
    .schedule-filter {
        >.title {
            color: $brand-color-secondary;
            font-size: 42px;
        }
        >.box-accordion {
            margin: 40px 0 0;
        }
    }
    @include breakpoint-min(large) {
        .box-news {
            max-width: 770px;
        }
    }
}