.site-nav {
    position: relative;

    @include breakpoint-min(large) {
        &::after {
            background-color: $border-color;
            bottom: 0;
            content: "";
            left: 0;
            position: absolute;
            @include size(1500%, 1px);
        }
        ul {
            > li {
                > a {
                    @include transition(
                        $prop: color
                    );
                    &:hover,
                    &.active {
                        color: $brand-color-secondary;
                    }
                }
            }
        }
    }

    @include breakpoint-max(large) {
        >.btn-toggle-menu {
            position: relative;
            &::before {
                content: "";
                @include imageBg(
                    $image: "menu.svg",
                    $size: 35px
                );
                position: absolute;
                right: 15px;
                top: calc(50% - 17.5px);
                @include size(35px)
            }
        }
        ul {
            > li {
                > a {
                    background-color: rgba(#000000, 0.15);
                    color: #ffffff;
                    @include transition;
                    &:hover,
                    &.active {
                        background-color: $brand-color-secondary;
                        columns: #ffffff;
                    }
                }
            }
        }
    }
}