.load-more {
    @include flex(
        $align: center,
        $justify: center
    );
    margin: 40px 0 0;
    > button {
        background-color: #ffffff;
        border: solid 2px $brand-color-secondary;
        color: $brand-color;
        cursor: pointer;
        display: block;
        font-size: 16px;
        font-weight: 700;
        line-height: em(16, 16);
        overflow: hidden;
        padding: 15px 35px;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        @include transition(
            $duration: 0.5s
        );
        white-space: nowrap;
        z-index: 1;
        &::before,
        &::after {
            background-color: $brand-color-secondary;
            content: "";
            pointer-events: none;
            position: absolute;
            @include size(100%);
            transform: rotate(-25deg);
            @include transition(
                $duration: 0.5s
            );
        }
        &::before {
            right: 50%;
            bottom: 50%;
            transform-origin: 50% 50%;
        }
        &::after {
            left: 50%;
            top: 50%;
            transform-origin: 50% 50%;
        }
        &:hover {
            background-color: $brand-color-secondary;
            color: #ffffff;
            &::before {
                right: 100%;
                bottom: 100%;
            }
            &::after {
                left: 100%;
                top: 100%;
            }
        }
    }
}