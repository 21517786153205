.box-text {
    a {
        @include transition;
    }
    > ul {
        > li {
            padding: 0 0 0 15px;
            position: relative;
            &::before {
                @include iconeBg(
                    $content: "\e917",
                    $fontSize: 8px
                );
                color: $brand-color-secondary;
                position: absolute;
                position: absolute;
                left: 0;
                top: 7px;
            }
        }
    }
}