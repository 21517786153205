.page-covenants-category {
    .list-items {
        @include flex();
    }
    .item {
        margin: -0.5px;
        width: 25%;
        > a {
            border: solid 1px $border-color;
            display: block;
            padding: 40px 25px;
            position: relative;
            text-align: center;
            &::before {
                background-color: rgba(#000000, 0.03);
                border: solid 5px $brand-color;
                content: "";
                left: -1px;
                top: -1px;
                position: absolute;
                @include size(calc(100% + 2px));
                opacity: 0;
                @include transition(
                    $duration: 0.5s
                );
            }
            &:hover {
                &::before {
                    opacity: 1;
                }
            }
        }
    }
    @include breakpoint-max(large) {
        .item {
            width: 33.33%;
        }
    }
    @include breakpoint-max(medium) {
        .item {
            width: 50%;
        }
    }
    @include breakpoint-max(small) {
        .item {
            width: 100%;
        }
    }
}