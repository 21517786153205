// Home Slide
.home-slide {
    .owl-carousel {
        .slide-item {
            > a {
                @include transition;
                &:hover {
                    background-color: rgba(#000000, 0.2);
                }
            }
        }
        .owl-nav {
            > span {
                border: solid 4px #8d95ab;
                border-radius: 50%;
                display: block;
                @include size(60px);
                @include transition;
                &::before {
                    @include iconeBg(
                        $fontSize: 16px
                    );
                    color: $brand-color-secondary;
                    position: absolute;
                    left: 0;
                    top: 0;
                    @include flex(
                        $align: center,
                        $justify: center
                    );
                    @include size(100%);
                    @include transition;
                }
                > span {
                    display: none;
                }
                &.owl-prev {
                    &::before {
                        content: "\e902";
                    }
                }
                &.owl-next {
                    &::before {
                        content: "\e903";
                    }
                }
                &:hover {
                    border-color: $brand-color-secondary;
                    &::before {
                        color: #ffffff;
                    }
                }
            }
        }
    }
    @include breakpoint-min(1440px) {
        .owl-carousel {
            .owl-nav {
                > span {
                    position: absolute;
                    top: calc(50% - 30px);
                    &.owl-prev {
                        left: 20px;
                    }
                    &.owl-next {
                        right: 20px;
                    }
                }
            }
        }
    }
    @include breakpoint-max(1439px) {
        .owl-carousel {
            .owl-nav {
                position: absolute;
                left: 0;
                bottom: 15px;
                width: 100%;
                @include flex(
                    $align: center,
                    $justify: center
                );
                > span {
                    position: relative;
                    & + span {
                        margin: 0 0 0 20px;
                    }
                }
            }
        }
    }
    @include breakpoint-max(large) {
        .owl-carousel {
            .owl-nav {
                display: none;
            }
        }
    }
}

// Home Headlines
.home-headlines {
    .box-right {
        .owl-navs {
            > span {
                border: solid 4px $brand-color;
                border-radius: 50%;
                cursor: pointer;
                display: block;
                position: relative;
                @include size(60px);
                @include transition;
                &::before {
                    @include iconeBg(
                        $fontSize: 16px
                    );
                    color: $brand-color;
                    position: absolute;
                    left: 0;
                    top: 0;
                    @include flex(
                        $align: center,
                        $justify: center
                    );
                    @include size(100%);
                    @include transition;
                }
                > span {
                    display: none;
                }
                &.owl-prev {
                    &::before {
                        content: "\e902";
                    }
                }
                &.owl-next {
                    &::before {
                        content: "\e903";
                    }
                }
                &:hover {
                    border-color: #ffffff;
                    &::before {
                        color: #ffffff;
                    }
                }
            }
        }
    }
    @include breakpoint-min(large) {
        .box-right {
            &::before {
                @include imageBg(
                    $image: "bg-manchetes.jpg",
                    $size: cover
                );
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                @include size(745px, 100%);
                z-index: -1;
            }
            .owl-navs {
                @include flex(
                    $direction: column,
                    $align: center
                );
                > span {
                    & + span {
                        margin: 20px 0 0;
                    }
                }
            }
        }
    }
    @include breakpoint-max(large) {
        .box-right {
            .owl-navs {
                > span {
                    &:hover {
                        border-color: $brand-color-secondary;
                        &::before {
                            color: $brand-color-secondary;
                        }
                    }
                }
            }
        }
    }
}

// Home Links
.home-links {
    ul {
        > li {
            > a {
                @include transition;
                .inner {
                    > i {
                        @include transition;
                    }
                    >.title {
                        position: relative;
                        @include transition;
                        &::before {
                            background-color: $border-color;
                            content: "";
                            position: absolute;
                            left: -20px;
                            top: 0;
                            opacity: 1;
                            @include size(2px, 100%);
                            @include transition;
                        }
                    }
                    >.link {
                        @include transition;
                        &::after {
                            @include iconeBg(
                                $content: "\e903",
                                $fontSize: 10px
                            );
                            color: $brand-color-secondary;
                            display: block;
                            margin: 0 0 0 10px;
                            transform: translateX(0);
                            @include transition;
                        }
                    }
                }
                &:hover {
                    background-color: $brand-color;
                    color: #ffffff;
                    .inner {
                        > i {
                            color: #ffffff;
                        }
                        >.title {
                            color: #ffffff;
                            &::before {
                                opacity: 0.1;
                            }
                        }
                        >.link {
                            color: #ffffff;
                            &::after {
                                color: #ffffff;
                                transform: translateX(5px);
                            }
                        }
                    }
                }
            }
        }
    }
}

// CTA Actions
.cta-actions {
    background-color: $brand-color;
    padding: 30px 0;
    .container {
        >.row {
            min-height: 140px;
        }
    }
    .title {
        color: $brand-color-secondary;
        font-size: 42px;
        line-height: em(42, 42);
        text-transform: uppercase;
    }
    .content {
        .inner {
            position: relative;
        }
        p {
            color: #ffffff;
            font-size: 16px;
            line-height: em(22, 16);
        }
        .btn-link {
            margin: 0 0 0 20px;
        }
    }

    @include breakpoint-min(large) {
        @include imageBg(
            $image: "bg-action.jpg",
            $size: cover
        );
        .content {
            .inner {
                @include flex(
                    $align: center,
                    $justify: space-between,
                    $wrap: nowrap
                );
                padding-bottom: 4px;
                border-bottom: solid 1px $border-color;
                &::after {
                    background-color: $brand-color-secondary;
                    bottom: -2px;
                    content: "";
                    left: 0;
                    position: absolute;
                    @include size(80px, 4px);
                }
            }
        }
    }
    @include breakpoint-max(large) {
        margin-left: 15px;
        margin-right: 15px;
        .title {
            text-align: center;
            margin: 0 0 20px;
            width: 100%;
        }
        .content {
            width: 100%;
            .inner {
                @include flex(
                    $align: center,
                    $direction: column
                );
                text-align: center;
                .btn-link {
                    margin: 20px 0 0;
                }
            }
        }
    }
}

// Home Banners
.home-banners {
    .banners-group {
        >.row {
            margin-bottom: -40px;
        }
        .banner {
            margin: 0 0 40px;
        }
    }
    @include breakpoint-max(large) {
        .banner {
            width: 100%;
        }
        .banners-group {
            margin: 20px 0 0;
            width: 100%;
            >.row {
                margin-bottom: 0;
            }
            .banner {
                margin: 0;
                & + .banner {
                    margin: 20px 0 0;
                }
            }
        }
    }
}