.buttons-group {
    >.button {
        cursor: pointer;
        @include transition(
            $duration: 0.5s
        );
        &:hover {
            background-color: $brand-color-secondary;
            color: #ffffff;
        }
    }
    @include breakpoint-min(large) {
        >.button {
            &::before,
            &::after {
                background-color: $brand-color-secondary;
                content: "";
                pointer-events: none;
                position: absolute;
                @include size(100%);
                transform: rotate(-25deg);
                @include transition(
                    $duration: 0.5s
                );
            }
            &::before {
                right: 50%;
                bottom: 50%;
                transform-origin: 50% 50%;
            }
            &::after {
                left: 50%;
                top: 50%;
                transform-origin: 50% 50%;
            }
            &:hover {
                &::before {
                    right: 100%;
                    bottom: 100%;
                }
                &::after {
                    left: 100%;
                    top: 100%;
                }
            }
        }
    }
}