.box-filters-news {
    .btn-toggle-filter {
        position: relative;
        &::before {
            @include imageBg(
                $image: "filter-results-button.svg",
                $size: 20px
            );
            content: "";
            right: 15px;
            top: calc(50% - 10px);
            position: absolute;
            @include size(20px);
        }
    }
}