.site-header {
    @include breakpoint-min(large) {
        .site-name {
            letter-spacing: 2px;
            >.fundation {
                padding: 0 0 0 15px;
                position: relative;
                &::before {
                    background-color: $text-color-light;
                    content: "";
                    left: 0;
                    position: absolute;
                    top: calc(50% - 10px);
                    @include size(2px, 16px);
                }
            }
        }
    }
}