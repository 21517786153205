.box-share {
    > ul {
        > li {
            > a {
                border-radius: 50%;
                @include transition;
                &:hover {
                    background-color: $brand-color;
                    color: #ffffff;
                }
            }
        }
    }
}