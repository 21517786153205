.card-video {
    @include flex(
        $justify: center
    );
    margin: 0 0 30px;
    > a {
        background-color: $brand-color;
        display: block;
        height: 100%;
        position: relative;
        @include transition;
        .-light & {
            background-color: #ffffff;
        }
        >.image {
            background-color: #000000;
            position: relative;
            &::before,
            &::after {
                content: "";
                left: 0;
                position: absolute;
                top: 0;
                @include size(100%);
                z-index: 1;
            }
            &::before {
                @include imageBg(
                    $image: "icon-play.png"
                );
            }
            &::after {
                background-image: url("../images/backgrounds/card-video-line-left.png"), url("../images/backgrounds/card-video-line-right.png");
                background-position: 0 center, right center;
                background-repeat: no-repeat;
            }
            > img {
                opacity: 0.5;
                @include transition;
            }
        }
        >.title {
            color: $brand-color-secondary;
            font-size: 26px;
            font-weight: weight(regular, secondary);
            line-height: em(26, 26);
            padding: 15px 15px 12px;
            text-align: center;
            text-transform: uppercase;
            @include transition;
        }
        &:hover {
            background-color: $brand-color-secondary;
            >.image {
                > img {
                    opacity: 0.35;
                }
            }
            >.title {
                color: #ffffff;
            }
        }
    }

    @include breakpoint-max(xlarge) {
        width: 33.33%;
    }
    @include breakpoint-max(large) {
        width: 50%;
        > a {
            >.title {
                font-size: 20px;
            }
        }
    }
    @include breakpoint-max(medium) {
        width: 100%;
    }
}