// Font Size Default
$font-size-default: 14px;

// Font family Primary
$font-primary: "Montserrat", sans-serif !default;

$font-primary-weight: (
  'regular': 400,
  'semibold': 600,
  'bold': 700
) !default;

// Font family Secondary
$font-secondary: "Teko", sans-serif !default;

$font-secondary-weight: (
  'light': 300,
  'regular': 400,
  'medium': 500,
  'semibold': 600,
  'bold': 700
) !default;


// Text color light
$text-color-light: #777777 !default;


// Copy text color dark
$text-color-dark: #4c4c4c !default;


// Brand color
$brand-color: #22509d !default;


// Brand color secondary
$brand-color-secondary: #ffba00;


// Container's maximum width
$max-width: 1200px !default;

// Error
$error: #cc0000 !default;

// Success
$success: #27ae60 !default;

// Border Color
$border-color: #e5e5e5 !default;


// Breakpoints map
// @prop {String} keys - Keys are identifiers mapped to a given length
// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints-max: (
  'small':  (max-width: 575px),
  'medium': (max-width: 767px),
  'large':  (max-width: 991px),
  'xlarge': (max-width: ($max-width - 1px))
) !default;


// Breakpoints map
// @prop {String} keys - Keys are identifiers mapped to a given length
// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints-min: (
  'small':  (min-width: 576px),
  'medium': (min-width: 768px),
  'large':  (min-width: 992px),
  'xlarge': (min-width: $max-width)
) !default;